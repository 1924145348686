import React from "react";

const List3 = () => {
 return (
<svg width={43} height={17} viewBox="0 0 43 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width={21} height={17} fill="#C9171E" />
  <rect x={22} width={21} height={17} fill="#C9171E" />
</svg>
  )
};

export default List3;