import React from "react";

const Logo = () => {
 return (
   <svg width={136} height={72} viewBox="0 0 136 72" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.21331 62.9579L0 61.8085L13.0561 1.72822L18.1249 0L45.022 19.4425L44.5723 24.3093L18.5827 37.3443L15.5496 32.2086L37.3125 21.3012L18.2639 7.53245L6.21331 62.9579Z" fill="#C9171E" />
  <path d="M71.4772 71.1308L52.0851 57.2642L53.5894 52.0632L70.2509 49.9111V41.0825L50.8016 47.9383L47.7685 66.2803L42.757 68.196L24.4359 55.7969L25.1799 50.71L45.087 43.6911L46.2806 36.4603L22.9562 46.7237L20.2256 41.4493L48.9458 28.8056L53.4423 31.887L51.8889 41.2944L72.2784 34.1125L76.5787 36.8434V49.0959L88.85 47.5063L89.7329 53.2942L76.5787 54.998V68.8319L71.469 71.1389L71.4772 71.1308ZM61.9038 56.8892L70.2509 62.8565V55.8132L61.9038 56.8892ZM32.8729 54.248L42.2828 60.6228L43.9833 50.335L32.8648 54.2561L32.8729 54.248Z" fill="#C9171E" />
  <path d="M92.6927 26.6733L92.6846 26.6652L88.278 20.3229L82.8087 26.5184L79.6121 24.3663L83.3973 19.0268L76.9551 16.7197L77.8625 13.1981L84.4111 13.7769V7.67102H88.3761V14.4372L94.5894 11.3231L96.478 14.535L90.9105 17.323L96.0283 24.7006L92.6927 26.6733Z" fill="#C9171E" />
  <path d="M107.143 58.5556C105.964 58.5556 105.006 59.5113 105.006 60.6865C105.006 61.8618 105.964 62.8175 107.143 62.8175C108.322 62.8175 109.28 61.8618 109.28 60.6865C109.28 59.5113 108.322 58.5556 107.143 58.5556ZM107.143 61.7456C106.547 61.7456 106.081 61.2677 106.081 60.6865C106.081 60.1054 106.56 59.6275 107.143 59.6275C107.726 59.6275 108.205 60.1054 108.205 60.6865C108.205 61.2677 107.726 61.7456 107.143 61.7456Z" fill="#C9171E" />
  <path d="M110.575 65.2197V67.0794H136V65.2197H110.575Z" fill="#C9171E" />
  <path d="M102.575 60.8609L100.265 62.1911L105.601 71.4073L107.912 70.077L102.575 60.8609Z" fill="#C9171E" />
  <path d="M98.5638 64.3271L96.0566 63.4172L93.5006 70.4197L96.0078 71.3297L98.5638 64.3271Z" fill="#C9171E" />
</svg>
  )
};

export default Logo;